<template>
    <main class="">

       <section class="grid-sect2 main --black ">
        <div class="video-container2" >


  <iframe class="video-content" width="100%" height="100%" src="https://www.youtube-nocookie.com/embed/7-RfKTpbOTY?controls=0&autoplay=1&enablejsapi=1&rel=0&loop=1&showinfo=0&modestbranding=0&iv_load_policy=3&color=white&mute=1&playlist=7-RfKTpbOTY"   allow="accelerometer; autoplay; "  allowfullscreen></iframe>

</div>
 
 </section>
 <div class="-flex-c" >
 
   <div class="info-container">
 <p class="sect-title maj" >ECOLARIUM 
</p>
 <p > 
    FauneEntropie is an interactive installation I think and created with Mélissa Bertide. <br>
Three participants can modify thanks to three knobs a graphic and sound environment based on different perceptions of nature.<br>
By modifying the nature according to the perception a resource appears in a hologram projected on a prism. </p> 
 </div>
 <div class="detail-projet">
 <p class="sect-title maj">2022</p>
 <p class=" gras maj"> <span> DESIGN <br> 3D & MUSIC <br> INSTALLATION </span></p>
 
 <!-- <p><a  style="border-bottom:solid 5px #A2A6A8 ;" href="" target="_blank" rel="noopener noreferrer"> <span class="gras">Listen the demo</span> </a></p> -->
 </div>
 
 
 </div>

 <section class="sect-page --black -flex-m  ">
 
 <div class="block-container1  -flex-m" >

    <img v-img class="cover-img" :src="require ('@/assets/Ecolarium/Compo3D2.jpg')" alt=" "> 

</div>
</section>
<section class="grid-sect2 main --black ">
        <div class="video-container2" >


  <iframe class="video-content" width="100%" height="100%" src="https://www.youtube-nocookie.com/embed/qTD4c_XAoto?&autoplay=1&enablejsapi=1&rel=0&loop=1&showinfo=0&modestbranding=0&iv_load_policy=3&color=white&mute=1&controls=0&playlist=qTD4c_XAoto"   allow="accelerometer; autoplay; "  allowfullscreen></iframe>

</div>
 
 
 </section> 

 <section class="grid-sect2 main --black ">
        <div class="video-container2" >


  <iframe class="video-content" width="100%" height="100%" src="https://www.youtube-nocookie.com/embed/Q4maP3Rayis?&autoplay=1&enablejsapi=1&rel=0&loop=1&showinfo=0&modestbranding=0&iv_load_policy=3&color=white&mute=1&controls=0&playlist=Q4maP3Rayis"   allow="accelerometer; autoplay; "  allowfullscreen></iframe>

</div>
 
 
 </section> 
 <section class="grid-sect2 main --black ">
        <div class="video-container2" >


  <iframe class="video-content" width="100%" height="100%" src="https://www.youtube-nocookie.com/embed/o29pbTM6c48?&autoplay=1&enablejsapi=1&rel=0&loop=1&showinfo=0&modestbranding=0&iv_load_policy=3&color=white&mute=1&controls=0&playlist=o29pbTM6c48"   allow="accelerometer; autoplay; "  allowfullscreen></iframe>

</div>
 
 
 </section> 

<section class="sect-page --black -flex-m  ">
 
 <div class="block-container1 -flex-m" >

    <img v-img class="cover-img" :src="require ('@/assets/Ecolarium/Canvas3d.jpg')" alt=" "> 

</div>
</section>

<section class="sect-page --black -flex-m  ">
 
 <div class="block-container1  -flex-m" >

    <img v-img class="cover-img" :src="require ('@/assets/Ecolarium/CanvasEcoUs.jpg')" alt=" "> 

</div>
</section>

 <section class="grid-sect2 main --black ">
        <div class="video-container2" >


  <iframe class="video-content" width="100%" height="100%" src="https://www.youtube-nocookie.com/embed/BarNUW0ryJc?&autoplay=1&enablejsapi=1&rel=0&loop=1&showinfo=0&modestbranding=0&iv_load_policy=3&color=white&mute=1&controls=0&playlist=BarNUW0ryJc"   allow="accelerometer; autoplay; "  allowfullscreen></iframe>

</div>
 
 </section> 


<section class="grid-sect2 main --black ">
        <div class="video-container2" >


  <iframe class="video-content" width="100%" height="100%" src="https://www.youtube-nocookie.com/embed/2N0QEg0DVPo?&autoplay=1&enablejsapi=1&rel=0&loop=1&showinfo=0&modestbranding=0&iv_load_policy=3&color=white&mute=1&controls=0&playlist=2N0QEg0DVPo"   allow="accelerometer; autoplay; "  allowfullscreen></iframe>

</div>
 
 </section> 
 
 <section class="sect-page --black -flex-m  ">
 
 <div class="block-container1 -flex-m" >

    <img v-img class="cover-img" :src="require ('@/assets/Ecolarium/CanvasEco.jpg')" alt=" "> 

</div>
</section>

 
 <div class="option-navigation">
  
   <h1> <a href="#top" class="btn-selection">TOP</a></h1>
 
 </div>
 <Footer/>
    </main>
 </template>
 
 <script>

 import Footer from "@/components/footer.vue"
 // import Vue from 'vue';
 // import VueImg from 'v-img';
 // Vue.use(VueImg);

 export default {
  components: {
    Footer,
  },
  mounted() {
    // Check if the page has been refreshed for this session
    const hasRefreshedSession = sessionStorage.getItem('hasRefreshed');
    
    // Check if the page has been refreshed for all sessions
    const hasRefreshedAll = localStorage.getItem('hasRefreshed');

    if (!hasRefreshedSession) {
      // Mark the page as refreshed for this session
      sessionStorage.setItem('hasRefreshed', 'true');
    }

    if (!hasRefreshedAll) {
      // If the page has not been refreshed for all sessions, refresh the page
      this.reloadPage();
    }

    // Reset the scroll position to (0,0)
    window.scrollTo(0, 0);
  },

  methods: {
    reloadPage() {
      // Mark the page as refreshed for all sessions
      localStorage.setItem('hasRefreshed', 'true');

      // Reload the page
      location.reload();
    },
  },
};
</script>
 
 <style lang="css" scoped>
 
 .video-container{
   grid-area: top/aside1/foot/main-end;
 }
 .video-container2{
   grid-area: top/aside1/foot/main-end;
   height: 100vh;
 }
 .video-content{
     /* border-radius: 30px; */
 width: 100%;
 }
 .grid-sect, .grid-sect2 img{
   /* object-fit: contain; */
 }
 .retour-info{
   grid-area: foot;
 }
 .img-flex{
     display: flex;
     flex-direction: row;
     /* flex-wrap: wrap; */
     justify-content: center;
     /* align-items: center; */
   
 }
 .img-bg2{
  margin-bottom: 30px;
     height: 95%;
     width: 80%;
 }
 .img-bg{
  margin-bottom: 30px;
     height: 100%;
     width: 96%;
 }
 .conception-container{
     grid-area: foot;
 }

 .block-container0{
     grid-row: top / aside3;
     grid-column: aside1 / main-end;
      /* padding: 15px 10px 0 10px;  */
   
     /* margin: auto; */
 }
 .block-container1{

    grid-row: aside1 / aside2;
grid-column: main-row1 / main-mid;
       /* padding: 15px 10px 0 0.4rem;   */
   
     /* margin: auto; */
 }
 .block-container2{
     grid-row: top / aside1;
     grid-column: main-mid / main-end;
      /* padding: 15px 10px 0 10px;  */
  
     /* margin: auto; */
 }
 .block-container3{
     grid-row: aside2 / aside3;
     grid-column: aside2 / main-row3;
      /* padding: 15px 10px 0 10px;  */
   
     /* margin: auto; */
 }
 .block-container4{
     grid-row: aside2 / aside3;
     grid-column: main-mid / main-end;
      /* padding: 15px 10px 0 10px;  */
  
     /* margin: auto; */
 }
 .reflexion-info{
     grid-area: foot;
 }
 
 
 .memento-bg{
   background-image:url('~@/assets/MementoMori/theroom.jpg');
  grid-area: top/top/aside4/main-end;
 
 }
 
 .mockup-img{
  width: 100%;
 height: 80%;
 
 }
 
 .full-img{
   margin-top: 0.2em;
   display: block;
     width: calc(100% + 2px);
     /* height: 100%; */
     height: calc(100% + 2px);
   /* height: 85vh; */
 }
 .flexR{
   display: flex;
   /* width: 50%; */
 }
 .grid-sect2{
         
         display: grid;
         position: relative;
         grid-template-columns: repeat(4,1fr);
         grid-template-rows: repeat(4, 1fr);
         grid-column-gap: 0.2em;
         grid-row-gap: 0.2em;
         grid-template-areas: 
         "top top top  top" 
         "aside1 main-row1 main-mid  main-end"
          "aside2 main-row2 main-mid  main-end" 
          "aside3 main-row3 main-mid   main-end"
         ;
         
         }
         @media only screen and (max-width: 1600px){
 
                 .grid-sect2{
         grid-template-columns: repeat(4, 1fr);
         
         grid-template-areas: 
         "top top top top-end" 
         "aside1 main-row1 main-mid main-end"
          "aside2 main-row2 main-mid main-end" 
          "aside3 main-row3 main-mid main-end"
          
           "foot foot foot foot"
         ;
         } 
 
          .grid-sect{
         grid-template-columns: repeat(4, 1fr);
         
         grid-template-areas: 
         "top top top top-end" 
         "aside1 main-row1 main-mid main-end"
          "aside2 main-row2 main-mid main-end" 
          "aside3 main-row3 main-mid main-end"
          "aside4 main-row4 main-mid main-end"
           "foot foot foot foot"
         ;
         } 
        
         .block-container{
         
         align-self: center;
         justify-content: center;
         }
         .cover-img{
           /* height: 80vh; */
         }
         .flexR{
           flex-direction: row;
           width: 100%;
           justify-content:center;
         }
 
 
         }
           /*Je creer la grille pour les ecrans > à 1005px */
         @media only screen and (max-width: 1185px){
 
              /* .grid-sect2{
         grid-template-columns: repeat(4, 1fr);
          grid-template-rows: repeat(4, 0.5fr);
         grid-template-areas: 
         "top top top top" 
         "aside1 main-row1 main main-end"
          "aside2 main-row2 main main-end" 
          "aside3 main-row3 main main-end"
        
         
         ;
         }  */
          /* .grid-sect{
         grid-template-columns: repeat(3, 1fr);
         
         grid-template-areas: 
         "top top top" 
         "aside1 main-row1 main-end"
          "aside2 main-row2 main-end" 
          "aside3 main-row3 main-end"
          "aside4 main-row4 main-end"
           "foot foot foot"
         ;
         }  */
         .block-container{
             
           justify-content: center;
        
 
         }
         .flexR{
            /* flex-direction: column; */
         }
         .cover-img{
         /* height: 33vh; */
         }
 
         }
 
   /*Je creer la grille pour les ecrans < à 735px */
         @media only screen and (max-width: 735px){
      
       .grid-sect2{
         display: block;
 
         grid-template-columns: repeat(2,1fr);
          grid-template-rows:  repeat(4, 0.5fr);
         grid-template-areas: 
         "top top  " 
         "aside1  main-row1"
          "aside2  main-row2" 
     
           "foot foot  "
         ;
         } 
 .grid-sect2 > div {
   /* margin-bottom: 5px; */
 }
 
          .grid-sect{
         grid-template-columns: repeat(2,1fr);       
         grid-template-areas: 
         "top top  " 
         "aside1  main-row1"
          "aside2  main-row2" 
          "aside3  mmain-row3"
          "aside4  main-row4"
           "foot foot  "
         ;
         } 
 
 .block-container{
      grid-area: top/aside2/foot/main-row1;
 }
 
 .img-flex{
     flex-direction: column;
 }
 .video-container{
   display: block;
 /* grid-area: top/aside2/foot/main-row4; */
 }
 .video-container{
  height: 100vh;
   display: block;
 /* grid-area: top/aside2/foot/main-row4; */
 }
 .cover-img2{
    width: calc(100% + 2px); }
 
  }
     
   /*Je creer la grille pour les ecrans < à 600px */
         @media only screen and (max-width: 600px) {
           body{
             padding: 0;
           }
           .grid-sect2{
         grid-template-columns: 1fr;
         grid-template-rows: 0.2fr  repeat(2, 0.5fr) 0.4fr;
         grid-row-gap: 2rem;
         grid-template-areas: 
         "top"
         "aside "
         " main-row1"
         "foot"
         ;}
 
 
         .grid-sect{
         grid-template-columns: 1fr;
         grid-template-rows: 0.2fr 0.5fr repeat(4, .5fr) 0.4fr;
         grid-row-gap: 2rem;
         grid-template-areas: 
         "top"
         "aside "
         " main-row1"
         " main-row2"
         "section-row1 "
         "section-row2 "
         "foot"
         ;
         }
 
         .block-container{
     grid-row: top/foot;
     grid-column: span 1;
   
 }
 section > .video-container2{
            height: inherit;
          }
  .video-container{
    grid-area: foot;
  }
  .video-container{
  height: 35vh;
   display: block;
 /* grid-area: top/aside2/foot/main-row4; */
 }
  .flexR{
    flex-direction: column;
    justify-self: center;
  }
 .cover-img{
   /* height: 40vh;
   margin: 5px; */
 }
         }
 </style>